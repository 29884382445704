<template>
  <div class="owner-list">
    <AdminTable title="All Owners"
    :list="ownerList" :fields="fields" :loading="loading" @update-list="update" @edit="editOwner" @delete="deleteOwner"/>
  </div>
</template>

<script>
import AdminTable from '@/components/admin/AdminTable'
import { ownerService } from '@/services/admin'

export default {
  name: 'AdminAllOwners',
  components: {
    AdminTable,
  },
  data() {
    return {
      ownerList: [],
      loading: false,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Name' },
        { key: 'tel', label: 'Phone number'},
        { key: 'email', label: 'Email' },
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['edit', 'delete'] },
      ]
    }
  },
  async beforeMount() {
    this.update()
  },
  methods: {
    async update() {
      this.loading = true
      this.ownerList = await ownerService.getOwners()
      this.$store.commit('adminOwnerStore/setOwners', this.ownerList)
      this.loading = false
    },
    editOwner(owner) {
      this.$store.commit('adminOwnerStore/setOwner', owner)
      this.$router.push({ name: 'owner-edit', query: { id: owner.id } })
    },
    async deleteOwner(owner) {
      this.$store.commit('adminOwnerStore/setOwner', null)
      const response = await ownerService.deleteOwner(owner.id)
      await this.update()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>